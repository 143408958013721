.toolbar {
  position: fixed;
  width: 100%;
  background: white;
  height: 56px;

}

.toolbar__navigation {
  display: flex;
  height: 100%;
  align-items: center;
  padding: 0 1rem;
}

.toolbar__logo {
  margin-left: 1rem;
}

.toolbar__logo a {
  color: #cfb87c;
  text-decoration: none;
  font-size: 1.5rem;
  font-weight: bold;
}
.spacer {
  flex: 1;
}

.toolbar__navigation-items ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.toolbar__navigation-items li {
  padding: 0 0.5rem;
}

.toolbar__navigation-items a {
  color: #231F20;
  text-decoration: none;
}

.toolbar__navigation-items a:hover,
.toolbar__navigation-items a:active {
  color: #cfb87c;

}

@media (max-width: 768px) {
  .toolbar__navigation-items {
    display: none;
  }
}

@media (min-width: 769px) {
  .toolbar__toggle-button{
    display: none;
  }
}
