

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.hero {
  background-size: cover;
  background-image: url('./Ralphie.jpg');
  height: 100vh;
  width: 100vw;

}
.hero h1{
  padding-top: 60px;
  color: #cfb87c;
}
.story {
  background: rgba(0,0,0,0.6);
  width: 33%;
  color: white;
  padding-left: 1rem;
  padding-right: 1rem;
  line-height: 1.6;
  font-size: 18px;
}

.story button {
  cursor: pointer;
  background-color: #0277BD;
  color: #fff !important;
  display: inline-block;
  padding: 5px 10px;
  font-weight: bold;
  transition: background-color 0.5s ease;
  margin-bottom: 5px;
  text-decoration: none !important;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  transition: -webkit-box-shadow 0.5s ease, background-color 0.5s ease, color 0.5s ease;
  transition: -moz-box-shadow 0.5s ease, background-color 0.5s ease, color 0.5s ease;
  transition: box-shadow 0.5s ease, background-color 0.5s ease, color 0.5s ease;
  background-color: #cfb87c; color: #111111 !important;
}
.footer {
  text-align: center;
}
