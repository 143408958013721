.about {
  background: rgba(0,0,0,0.6);
  color: white;
  width: 45%;
  padding-left: 1rem;
  padding-right: 1rem;
  height: 100vh;
  line-height: 1.6;
}
.about h1{
  padding-top: 60px;
  color: #cfb87c;
}
.image {
  background-size: cover;
  background-image: url('../Folsom_Field.jpg');
  height: 100vh;
  width: 100vw;
}
